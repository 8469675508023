::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9e9ea2;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9ea2;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9ea2;
}
.padding-Bottom-10 {
  padding-bottom: 10px;
}

.date-style{
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}