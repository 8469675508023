@import "../sass/colors";
.dashboard-container{
    width: 100%;
    padding: 20px;
}
.nav-bar {
    flex-grow: 1;
    background-color: white;
}

.drafts-view {
    background-color: #F4F4F4;
    width: 1040px;
    margin-left: 68px;
    margin-bottom: 26px;
}

.products-view{
    width: 1040px;
    display: flex;
    flex-direction: row;
}

.draft-table-view, .product-table-view{
    width: 60%;
    padding: 10px;
    box-sizing: content-box;
    margin-left: 40px;
}

.draft-table-header{
    display: grid;
    grid-template-columns: 25% 25% 50%;
    line-height: 12.96px;
    font-size: 18px;
    font-weight: 700;
    // padding: 20px;
    font-family: "Roboto Mono";
}

.draft-table-content{
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 25% 25% 50%;
    padding: 20px;
    margin: 10px;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-weight: 400;
}

.product-table-view{
    width: 60%;
    padding: 10px;
    box-sizing: content-box;
}

.product-table-header{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    line-height: 12.96px;
    font-size: 18px;
    font-weight: 700;
    font-family: "";
    padding: 20px

}

.product-table-content{
    background-color: #ffffff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px;
    margin: 10px;
    font-family: "Roboto Mono";
    font-size: 12px;
    font-weight: 400;
}

.table-title{
    font-size: 24px;
    font-weight : 700;
    padding: 20px;
    font-family: "Roboto";
    line-height: 28.13px;
}

.wrapper-right-arrow{
    padding-top: 60px;
    padding-left: 15px;
}

.right-arrow {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid $Pale-Gray-1 !important;
    border-radius: 50%;
    opacity: 0.7;
    background-color: white;
}
.left-arrow-nav {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid $Pale-Gray-1 !important;
    border-radius: 50%;
    opacity: 0.7;
    background-color: white;
    position: fixed;
    left: 160px;
    top: 75px;
}
.wrapper{
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 5px;
}
.wrapper .org-avatar-text{
    width: 24px;
    height: 24px; 
    background-color: $GR-Primary !important;
    font-family: SFProDisplayRegular !important;
    font-weight: 500;
    font-size: 11px;
    color: #ffffff;
    line-height: 3.13px;
}

.avatar-count{
    width: 24px;
    height: 24px; 
    background-color: #DD6E0f !important;
    font-family: SFProDisplayBold !important;
    font-weight: 500;
    font-size: 11px;
    color: #ffffff;
}

.card-title{
   font-size: 18px;
   font-family: SFProDisplaySemibold !important;
   color: $GR-Text;
   //border-bottom: 1px solid; 
}

.card-buttons{
    display: flex;
    flex-direction: row; 
    justify-content:flex-start;
    gap: 8px;
    padding-top: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.card-blocks{
    display: flex;
    flex-direction: column;
    margin-top: 30px;

}

.dashboard-title{
    font-family: SFProDisplaySemibold !important;
    font-size : 18px;
    padding-left: 8px;
    text-transform: capitalize;
    color: $GR-Text;
}

.action-icon{
    color: $GR-Primary;
}

.visit-data-sheet{
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.view-icon{
    height: 30px;
}

.edit-icon{
    height: 25px;
    margin-bottom: 3px;
    margin-left: 4px;
}

.org-drop-down{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-family: SFProTextRegular !important;
    font-size : 14px;
    text-transform: capitalize;
    color: #24242D,
   }

.list-content-label{
    padding: 18px 20px 5px 20px;
    font-family: SFProTextRegular !important;
    font-size: 12px;
    font-weight: 700;
    color: $GR-Text;
    line-height: 11.94px;
}

.maps-toolbar{
    padding-bottom: 15px;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.maps-toolbar .basic-single-select{
    width: 130px;
    border: none;
    
}

.maps-toolbar .basic-single-select .css-1okebmr-indicatorSeparator{
    display: none;
   
}
.basic-single-select .css-yk16xz-control{
    border: none !important;
    display: flex;
    gap: 2px;
}
.basic-single-select .css-1hwfws3{
    display: flex;
    justify-content: flex-end;

}

.basic-single-select{
 &.basic-single-select .css-1wa3eu0-placeholder, &.basic-single-select .css-tlfecz-indicatorContainer{
    color: #24242D !important;
    font-size: 12px;
    font-family: SFProTextBold !important;
    font-weight: bold;
    text-align: center;
    padding: 0px;
 }
}

.avatar-editor{
    display: flex !important;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    //flex-wrap: wrap;
}

.btn-single-select{
    width: 20px;
}

.btn-single-select .css-1hwfws3{
    display: none;
}

.split-button-container{
    background-color: #CDB9EA;
    border-radius: 10px;
    max-width: max-content;
}

.split-button-container .split-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 35px;
}

.split-button-container .split-btn-group .split-btn{
    background-color: $GR-Lavender !important;
    font-family: SFProTextRegular !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.24px;
    color: $GR-Text;
    border: none;
    outline: none;
    border-radius: 8px 0px 0px 8px;
}

.split-button-container .split-btn-group .split-btn-icon{
    background-color: $GR-Lavender !important;
    font-family: SFProTextRegular !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.24px;
    color: $GR-Text;
    border: none;
    outline: none;
    border-radius: 0px 8px 8px 0px;
    border-left: 1px solid #9A87B6;
}


.btn-list{
    display: flex !important;
    flex-direction: column;
    gap: 15px;
    
}

.btn-list .btn-text{
    font-family: SFProTextRegular !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.24px;
    color: $GR-Text;
}

.org-dropdown{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.org-dropdown .org-title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;

}

.org-dropdown .org-title:hover{
    background-color: #ECECF1;

}

.org-block:hover{
    background-color: #ECECF1;
}

.signout-btn{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    border-top: 0.5px solid #ECECF1 ;
    padding-top: 10px;
}