@import '../sass/colors';
.input-active {
  font-size: 16px;
  font-family: "CiscoSansTTLight";
  color: #39393b;
}

.css-1uccc91-singleValue {
  font-size: 16px !important;
  color: #39393b !important;
  font-family: "CiscoSansTTLight" !important;
}
.text-processingnode {
  display: flex;
  align-items: initial;
  justify-content: center;
  min-height: 75px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  padding-bottom: 20px;
  align-items: center;
}

.hover-users:hover {
  opacity: 1;
}
.hover-users {
  opacity: 0.3;
}
.selected{
  opacity: 1;
}
.padding15-processingnode{
	background: white;
	margin-bottom: 30px;
	border-radius: 8px;
}

.Error-color{
color: $ErrorMessage-Text;
}

.pn-dropdown .css-2b097c-container{
    width: 430px;
    white-space: pre-wrap;
  
}