@import "../sass/colors";
.form-container {
  display: flex;
  flex-direction: row;
}
.small-square-box {
  float: left;
  margin: 15px;
}
.data-box {
  border-left: 2px solid $GR-Selected;
  padding-left: 15px;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-container {
  display: flex;
  flex-direction: row;
}
.small-square-box {
  float: left;
  height: 20px;
  width: 20px;
}
.data-box {
  padding-left: 15px;
  margin-bottom: 30px;
  width: 60%;
}
.padding5 {
  padding: 5px;
  padding-top: 0px;
  padding-left: 0px;
}
.border-bottom {
  width: 100%;
  border-bottom: 1px solid $GR-Pale-Gray;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 38px;
  min-height: 20px;
  margin-right: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9e9ea2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  height: 23px;
}

.slider.round:before {
  border-radius: 50%;
}

.color-red{
  color: red;
  font-size: 15px;
  font-weight: bold;
}