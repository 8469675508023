@import "../sass/colors";

.main-container {
  padding: 2% 10%;
}

.container-1 {
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid $GR-Pale-Gray;
}

.container-2{
  width: 100%;
  margin-bottom: 12px;
  padding-top: 5%;
}

.container-heading {
  padding-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.header {
  font-size: 1.5rem;
  text-align: right;
  padding-right: 20px;
  font-size: 24px;
  color: #39393b;
  font-family: "CiscoSansTTLight";
}

hr {
  height: 0;
  width: 100%;
  text-align: left;
  margin: 0;
}

.container-content {
  width: 100%;
  text-align: right;
  justify-content: space-between;
}

.content-left {
  width: 25%;
}

.productName {
  padding-bottom: 30px;
  font-size: 1.2rem;
}

.dataTypes {
  padding-bottom: 20px;
  font-size: 1.2rem;
}

.connet {
  font-size: 1.2rem;
}

.content-group {
  padding-bottom: 20px;
}

.label {
  padding-bottom: 10px;
}

.labelNew {
  padding-bottom: 6px;
}

.content-right {
  width: 70%;
  text-align: left;
}

.input-left {
  width: 15%;
}

.input-right {
  width: 90%;
}

.hidden {
  visibility: hidden;
}

.flex {
  display: flex;
}
.box {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.box-color-cyan {
  background-color: #4eb3df;
}

.box-color-yellow {
  background-color: #ffc435;
}

.box-color-red {
  background-color: #e03c2d;
}

.container-content-1 {
  justify-content: space-between;
  padding: 2% 10%;
  width: 100%;
}

.centerPolicies,
.centers,
.otherCenters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.centerLabel {
  text-align: right;
  font-size: 1.2rem;
}

.rectangle {
  width: 80%;
  padding: 20px 0;
  background-color: #e8ebf1;
  border-radius: 8px;
  justify-content: space-evenly;
}

.dataBox {
  width: 33%;
  text-align: center;
  padding: 15px;
}

.dataInputBox {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding15 {
  background: white;
  margin-bottom: 10px;
  border-radius: 8px;
}
.overlay-processingNode {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(43,85,146,90);
  background-color: #2b5592;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.9;
  z-index: 2;
  cursor: pointer;
}

.text {
  // padding: 15px;
  display: flex;
  align-items: initial;
  justify-content: center;
  min-height: 75px;
  padding-top: 20px;
}
.card-text {
  // padding: 15px;
  display: flex;
  align-items: initial;
  justify-content: center;
  min-height: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.border-radius {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.border-cyan {
  background: #4eb3df;
  height: 30px;
}

.border-cyan15 {
  background: #4eb3df;
  height: 15px;
}

.border-orange15 {
  background: #ffc435;
  height: 15px;
}

.border-red15 {
  background: #e03c2d;
  height: 15px;
}

.border-cyan10 {
  background: #4eb3df;
  height: 10px;
}

.border-red10 {
  background: #e03c2d;
  height: 10px;
}

.border-orange10 {
  background: #ffc435;
  height: 10px;
}

.border-orange {
  background: #ffc435;
  height: 30px;
}

.border-red {
  background: #e03c2d;
  height: 30px;
}

.margin-bottom {
  margin-bottom: 5px;
}

.box-headers {
  width: 80%;
  margin-left: auto;
  font-size: 1.2rem;
}

.width33 {
  width: 33%;
  padding: 10px;
}

.itmes-center {
  align-items: center;
}

.hours,
.days,
.noRetention {
  padding-bottom: 15px;
  font-size: 1.2rem;
}

.text-decoration-connect {
  font-family: "CiscoSansTTLight";
  font-size: 16px;
  color: #39393b;
}

.no-data-entered {
  background-color: #fff8dc;
}

.rectangle-wrap {
  width: 80%;
  padding: 20px 20px;
  background-color: #e8ebf1;
  border-radius: 8px;
  // justify-content: space-evenly;
  display: FLEX;
  flex-wrap: WRAP;
}

.no-Nodes {
  background-color: #fff8dc;
}

#CustomerProcessingNodes:hover {
  background-color: #c4cee0;
}
#CiscoProcessingNodes:hover {
  background-color: #c4cee0;
}
#SubprocessorProcessingNodes:hover {
  background-color: #c4cee0;
}
#CiscoRetentionNodes:hover {
  background-color: #c4cee0;
}
#SubprocessorRetentionNodes:hover {
  background-color: #c4cee0;
}

.edit-text {
  font-size: 16px;
  font-family: CiscoSansTTBold;
  color: #2b5592;
}
.warn-edit {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 10%;
  padding-left: 20px;
  margin-bottom: 20px;
}
.Product-name:hover {
  background-color: $Pale-Gray-1;
}
.Sheet-url:hover {
  background-color: $Pale-Gray-1;
}
.cursor-pointer {
  cursor: pointer;
}
.processing-title {
  width: 80%;
  border-radius: 8px;
  justify-content: space-evenly;
  display: flex
}
.processing-subtitle {
  width: 33%;
  text-align: center;
  padding: 15px;
}

.divider-line{
  border: 1px solid $GR-Pale-Gray
}