$Cisco-Blue: #049fd9;
$Pale-Gray-1: #e8ebf1;
$Primary-Text: #39393b;
$Secondary-Text: #797979;
$Placeholder-Text: #9e9ea2;
$ErrorMessage-Text: #cd0000;
$WarningMesage-Text: #916c00;
$Registration: #4eb3df;
$Generated: #ffc435;
$Host-and-Usage: #e03c2d;
$Hovered-primary-Button: #0284b4;
$Hovered-secondary-Button: #c4c8d1;
$Pressed-primary-Button: #47afd5;
$Pressed-secondary-Button: #f1f4f8;
$Clicked-primary-Button: #0284b4;
$Clicked-secondary-Button: #c4c8d1;
$Danger-Button:#E66666;
$Selected: #F3F5F6;
$GR-Primary: #4C42BF;
$GR-Sage: #9FD6BA;
$GR-Lavender: #CDB9EA;
$GR-Yellow: #EFC40F;
$GR-Dark: #20203F;
$GR-Text:#24242D;
$GR-Secondary-Text: #595353;
$GR-Pale-Gray: #E8EBF1;
$GR-Selected: #F8F7FA;