@import "./components/sass/colors";
.primary-button {
  background-color: $Cisco-Blue !important;
  text-transform: none !important;
  font-size: 18px !important;
  min-width: 160px !important;
  height: 45px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 0.75 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: white !important;
}
.primary-button:focus {
  // border: 1px solid $Cisco-Blue !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Cisco-Blue !important;
}
.primary-button:hover {
  background-color: $Hovered-primary-Button !important;
}
.primary-button:active {
  background-color: $Clicked-primary-Button !important;
}
.primary-button:disabled {
  background-color: $Cisco-Blue !important;
  opacity: 0.5 !important;
}
.danger-button{
  background-color: $Danger-Button !important;
  text-transform: none !important;
  font-size: 18px !important;
  min-width: 160px !important;
  height: 45px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 0.75 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: white !important;
}
.danger-button:focus {
  // border: 1px solid $Cisco-Blue !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Danger-Button !important;
}
.danger-button:hover {
  background-color: #BB3B3B !important;
}
.danger-button:active {
  background-color: #BB3B3B  !important;
}
.danger-button:disabled {
  background-color:#BB3B3B  !important;
  opacity: 0.5 !important;
}
.secondary-button {
  background-color: $Pale-Gray-1 !important;
  text-transform: none !important;
  font-size: 18px !important;
  min-width: 160px !important;
  height: 45px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 0.75 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: black !important;
}
.secondary-button:focus {
  // border: 1px solid $Pale-Gray-1 !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Pale-Gray-1 !important;
}
.secondary-button:hover {
  background-color: $Hovered-secondary-Button !important;
}
.secondary-button:active {
  background-color: $Clicked-secondary-Button !important;
}
.secondary-button:disabled {
  background-color: $Pale-Gray-1 !important;
  opacity: 0.5 !important;
}

.secondary-button-lable {
  background-color: $Pale-Gray-1 !important;
  text-transform: none !important;
  font-size: 18px !important;
  min-width: 160px !important;
  height: 45px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 2.5 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: black !important;
}
.secondary-button-lable:focus {
  // border: 1px solid $Pale-Gray-1 !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Pale-Gray-1 !important;
  outline: none;
}
.secondary-button-lable:hover {
  background-color: $Hovered-secondary-Button !important;
}
.secondary-button-lable:active {
  background-color: $Clicked-secondary-Button !important;
}
.secondary-button-lable:disabled {
  background-color: $Pale-Gray-1 !important;
  opacity: 0.5 !important;
}
.selected-file {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #e8ebf1 !important;
  text-transform: none !important;
  font-size: 18px !important;
  height: 35px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 1 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: black !important;
}
.border-bottom-1 {
  border-bottom: 1px solid $GR-Pale-Gray;
}
.input-wrapper {
  display: flex;
  justify-content: space-between;
}

.input-text-active {
  color: $Primary-Text;
  font-size: 16px;
  font-family: CiscoSansTTLight;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  outline: none;
  border-bottom: 0px;
}
.input-label {
  font-size: 14px;
  font-family: "CiscoSansTTRegular";
  color: $Primary-Text;
}
.review-section-header {
  color: $Primary-Text;
  font-size: 18px;
  font-family: "CiscoSansTTLight";
}
.review-section-title {
  color: $Primary-Text;
  font-size: 24px;
  font-weight: 400;
  font-family: "CiscoSansTTLight";
}
.review-input-label {
  color: $Primary-Text;
  font-size: 16px;
  font-family: "CiscoSansTTRegular";
}
.review-input-secondary-label {
  color: $Primary-Text;
  font-size: 14px;
  font-family: "CiscoSansTTRegular";
}
.review-input-fields {
  color: $Primary-Text;
  font-size: 16px;
  font-family: "CiscoSansTTLight";
  line-height: 22px;
}
.width-428 {
  width: 428px;
}
.width-550{
  width: 550px;
}
.width-200 {
  width: 200px;
}
.Registration {
  background-color: $Registration;
}
.Generated {
  background-color: $Generated;
}
.Host-and-Usage {
  background-color: $Host-and-Usage;
}
.height-10 {
  height: 10px;
}
.height-15 {
  height: 15px;
}
.height-30 {
  height: 30px;
}
.border-top-left-right-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.tc {
  text-align: center;
}
.pr-20 {
  padding-right: 20px;
}
.pl-10 {
  padding-left: 20px;
}
.width-25-per {
  width: 25%;
}
.w-70-per {
  width: 70%;
}
.width-15-per {
  width: 15%;
}
.w-33-per {
  width: 33%;
}
.p0 {
  padding: 0px;
}
.m0 {
  margin: 0px;
}
.pb-10 {
  padding-bottom: 10px;
}
.yes-button {
  background-color: $Cisco-Blue !important;
  text-transform: none !important;
  font-size: 16px !important;
  // min-width: 160px !important;
  height: 25px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 0.75 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: white !important;
}
.yes-button:focus {
  // border: 1px solid $Cisco-Blue !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Cisco-Blue !important;
}
.yes-button:hover {
  background-color: $Hovered-primary-Button !important;
}
.yes-button:active {
  background-color: $Clicked-primary-Button !important;
}
.yes-button:disabled {
  background-color: $Cisco-Blue !important;
  opacity: 0.5 !important;
}
.no-button {
  background-color: $Pale-Gray-1 !important;
  text-transform: none !important;
  font-size: 16px !important;
  // min-width: 160px !important;
  height: 25px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-family: "CiscoSansTTRegular" !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  line-height: 0.75 !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: black !important;
}
.no-button:focus {
  // border: 1px solid $Pale-Gray-1 !important;
  box-shadow: 0 0 1px 3px #ffffff, 0 0 2px 4px $Pale-Gray-1 !important;
}
.no-button:hover {
  background-color: $Hovered-secondary-Button !important;
}
.no-button:active {
  background-color: $Clicked-secondary-Button !important;
}
.no-button:disabled {
  background-color: $Pale-Gray-1 !important;
  opacity: 0.5 !important;
}
.vertical-line{
 border-right: 1px solid $Pale-Gray-1; 
 padding-left: 10px;
 margin-right: 10px;
 height: 25px;
}
// .home-icon-opacity{
// opacity: 0.4;
// cursor: pointer;
// }

// .home-icon-opacity:hover{
//   opacity: 1;
//   cursor: pointer;
// }
.home-icon{
color: #797979;
cursor: pointer;
}
.home-icon:hover{
  color: black;
  }

.d-flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}

.flex-col{
  flex-direction: column;
}

.align-items-center{
  align-items: center;
}

.justify-content-start{
  justify-content: center;
}


.justify-content-start{
  justify-content: flex-start;
}


.justify-content-end{
  justify-content: flex-end;
}


.justify-content-space-between{
  justify-content: space-between;
}

.justify-content-space-around{
  justify-content: space-around;
}

.justify-content-space-evenly{
  justify-content: space-evenly;
}

.GR-h2{
  font-family: SFProDisplaySemibold !important;
  font-size: 18px;
  color: $GR-Text;
}

.bold-body-text{
  font-family: SFProTextBold !important;
  font-size: 14px;
  color: $GR-Text;
  line-height: 128%;
}

.body-text{
  font-family: SFProTextRegular !important;
  font-size: 12px;
  font-weight: 400;
  color: $GR-Text;
  line-height: 15.24px;
}

.label-text-II{
  font-family: SFProTextRegular !important;
  font-size: 8px;
  font-weight: 400;
  color: $GR-Text;
  line-height: 9.55px;

}

.label-text{
  font-family: SFProTextBold !important;
  font-size: 11px !important;
  font-weight: bold !important;
  color: $GR-Text !important;
}

.primary-regular-button{
  background-color: $GR-Lavender !important;
  text-transform: none !important;
  font-size: 14px !important;
  min-width: 100px !important;
  height: 34px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: SFProTextRegular !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: $GR-Text;

}
.secondary-regular-button{
  background-color: $GR-Pale-Gray !important;
  text-transform: none !important;
  font-size: 14px !important;
  min-width: 100px !important;
  height: 34px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-family: SFProTextRegular !important;
  box-shadow: none !important;
  white-space: nowrap !important;
  border-radius: 20px !important;
  text-align: center !important;
  color: $GR-Text !important;
}

.url-link{
  color: $GR-Primary !important;
  font-family: SFProTextBold !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 128%;
}

.text-transform-captial{
  text-transform: capitalize;
}


.snackbar{
  padding-bottom: 45px;
}

.word-break{
  word-wrap: break-word;
  word-break: break-word;
}

.org-name-text{
  font-family: SFProTextBlack !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.33px;
  color: $GR-Text !important;

}

.user-email-text{
  font-family: SFProTextBlack !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 9.33px;
  color: $GR-Text !important;
}

.avatar-text{
  font-family: SFProTextRegular !important;
  font-size: 6px;
  font-weight: 400;
  line-height: 7.62px;
  color: #000000;
}

.chip-text{
  font-family: SFProTextBlack !important;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.7px;
  color: $GR-Text !important;
}



/*  Latest fonts */

.org-title-txt{
  font-family: SFProTextRegular !important;
  color: $GR-Text !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.33px;
}


.table-header-text{
  font-family: SFProTextRegular !important;
  color: $GR-Text !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.24px;
}


