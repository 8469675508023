@import "../sass/colors";
.css-1wa3eu0-placeholder{
color: #9E9EA2 !important;
font-size: 16px;
font-family: "CiscoSansTTLight" !important;
}
.text-area-style{
min-width: 428px;
max-width: 428px;
border-top: none;
border-left: none;
border-right: none;
border-bottom: 1px solid $GR-Pale-Gray;
font-size: 16px;
font-family: "CiscoSansTTLight" !important;
color:"#39393B"
}

textarea::-webkit-input-placeholder {
    color: #9E9EA2 ;
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
    color: #9E9EA2 ;
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: #9E9EA2 ;
  }
  
  textarea:-ms-input-placeholder {
    color: #9E9EA2 ;
  }
  
  textarea::placeholder {
    color: #9E9EA2 ;
  }

textarea {
    min-width: 428px;
    max-width: 428px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $GR-Pale-Gray;
    font-size: 16px;
    font-family: "CiscoSansTTLight" !important;
    // resize: vertical;
    outline:none;
}

.css-1rhbuit-multiValue {
  border-radius: 10px !important;
}

.css-yk16xz-control {
  border: 0px !important;
  border-bottom: 1px solid $GR-Pale-Gray !important;
  border-radius: 0px !important;
}

.css-1pahdxg-control  {  
  border-color:white !important;
  box-shadow:0 0 0 white !important;
  border-radius: 0 !important;
  border-style: none !important;
  border-width: 0 !important;  border-bottom:1px solid $GR-Pale-Gray !important;
}

.css-1alnv5e:hover {
  color: black !important;
  background-color: #e6e6e6 !important;
}

.css-2b097c-container{
width: 550px;
white-space: pre-wrap;
}