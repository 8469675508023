.radio-button {
  color: black !important;
  padding: 0px !important;
}

.AddNode-Button:hover {
  background-color: #049fd9 !important;
}

::placeholder {
  color: #9e9ea2;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9ea2;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9ea2;
}
.helper-text {
  margin: unset;
  font-size: 12px;
  font-family: "CiscoSansTTRegular";
  color: #9e9ea2;
}

.css-1hwfws3 {
  padding: 0px !important;
}

.padding15-datainput {
  background: white;
  margin-bottom: 30px;
  border-radius: 8px;
}

.label-button {
  white-space: nowrap;
  padding: 15px 25px;
  border-radius: 20px;
  background-color: rgb(232, 235, 241);
  height: 45px;
  min-width: 160px;
  line-height: 1;
  text-align: center;
}