.layout-flex {
  display: flex;
}

.padding-9 {
  padding: 9px;
}

.file-custom {
  right: 0;
  z-index: 5;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  color: #9E9EA2;
}

.Existing-files{
  padding-left: 20%;
  float: left;
  display: flex;
  flex-direction: column;
}

.file-radio-wrapper{
display: flex;
}

.validate_save_svg_button{
    min-width: 160px;
    width: 100%;
    font-size: 18px;
    padding-left : 25px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: #049FD9 !important;;
    color : white;
    border-radius: 30px;
    height: 45px;
    line-height: 0.75;
}

.MuiDialogTitle-root{
  padding: 16px 45px;
}
