.PrivateTabIndicator-root-29{
  background-color:  #049FD9 !important;
}
.PrivateTabIndicator-vertical-32{
  background-color:  #049FD9 !important;
}
.PrivateTabIndicator-colorSecondary-31 {
  background-color:  #049FD9 !important;
}

.test {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .test {
    width: 55%;
    margin-left: 10% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .test {
    width: 70%;
    margin-left: 5% !important;
  }
}

.MuiTab-textColorInherit.Mui-selected{
  color: #049FD9 !important;
}