@import "../sass/colors";

.left-arrow {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid $Pale-Gray-1 !important;
    border-radius: 50%;
    opacity: 0.7;
    background-color: white;
    position: fixed;
    left: 178px;
    top: 15px
}