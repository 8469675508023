@import "../sass/colors";
.buttonIn {
    margin-top: 20px;
    width: 300px;
    position: relative;
}
  
.cmt-input {
    margin: 0px;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 30px;
    max-width: 100%;
    min-width: 100%;
    outline: none;
    height: 36px;
    border: 0.5px solid #838383;
    font-family: "CiscoSansTTRegular" !important;
    font-size: 14px;
    color: #5F5F5F;
    resize: none;
    border-radius: 6px;
}
  
.input-button {
    position: absolute;
    right: 12px;
    z-index: 2;
    border: none;
    border-radius: 5px;
    top: 5px;
    height: 25px;
    cursor: pointer;
    color: #049fd9;
    background-color: #CDB9EA;;
    transform: translateX(2px);
    font-size: 10px;
    
}

.image-box{
    background-color: $GR-Secondary-Text;
    height: 600px; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-box .error-details{
    margin-top: 18%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    width: 50%;
    color: #FFFFFF;

}
.image-box .error-details .error-title{
    font-family: CiscoSansTTRegular !important;
    font-size: 24px;
    font-weight: 500;
}

.image-box .svgImg{
    flex: 1;
}

.image-box .error-details .error-content{
    font-family: CiscoSansTTRegular !important;
    font-size: 14px;
    font-weight: 500;
}
.image-toolbar{
    display: flex;
    flex-direction: row;
    gap: 50%
}

.tool-buttons{
    display: flex;
    flex-direction: row; 
    justify-content:flex-start;
    gap: 8px;
    padding-top: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.card-blocks{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.reviewers-block{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
