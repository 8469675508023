.ul1 {
  padding-inline-start: 20px;
}

.Font-family-CiscoSansTTRegular {
  font-family: "CiscoSansTTRegular" !important;
}

.label-button {
  white-space: nowrap;
  padding: 15px 25px;
  border-radius: 20px;
  background-color: rgb(232, 235, 241);
  height: 45px;
  min-width: 160px;
  line-height: 1;
  text-align: center;
}

input[type="file"] {
  display: none;
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}
// .MuiInputBase-input{
//   padding: 0px !important;
// }

.choose-file {
  border-radius: 20px;
  /* min-width: 160px; */
  /* line-height: 0; */
  font-size: 16px;
  /* padding-bottom: 10px; */
  background-color: #e8ebf1;
  margin-bottom: 10px;
  padding-left: 10px;
    padding-right: 10px;
  text-align: center;
  font-family: "CiscoSansTTRegular";
}

.MuiInputBase-input{
padding: 0px 0 6px !important;
}

// .MuiIconButton-label{
// padding-bottom: 10px !important;
// }


.date-style{
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}

.file-name-chip {
  margin-top: 8px;
  margin-right: 22%;
  background-color: #9FD6BA;
  max-width: 220px;
}